<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <div class="logo"></div>
    <div class="contactBox">
      <div @click="toBusiness"></div>
      <div @click="toTg"></div>
      <div @click="toPotato"></div>
    </div>
    <div class="figureBox"></div>
    <div class="logoText"></div>
    <div class="downloadBox">
      <img class="qrcodeBox" :src="qrImg" alt="" />
    </div>
  </div>
</template>

<script>
import autofit from "autofit.js";
autofit.init();
export default {
  props: ["qrImg"],
  mounted() {
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#pcm-wrapper",
        resize: true,
      },
      false
    ); // 可关闭控制台运行提示输出
  },
  methods: {
    toBusiness() {
      window.open("https://t.me/laiango2naduo");
    },
    toTg() {
      window.open("https://t.me/loliidao");
    },
    toPotato() {
      window.open("https://dlptm.org/loliidao");
    },
  },
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  background: url("./../../../assets/images/pc/bg.webp") no-repeat;
  background-size: 100% 100%;
  .logo {
    height: 94px;
    width: 333px;
    background: url("./../../../assets/images/pc/logo.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 95px;
    left: 91px;
  }
  .contactBox {
    position: fixed;
    right: 120px;
    top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
    height: 52px;
    width: 530px;
    background: url("./../../../assets/images/pc/contactBox.png") no-repeat;
    background-size: 100% 100%;
    div {
      height: 52px;
      width: 163px;
    }
  }
  .figureBox {
    height: 947px;
    width: 870px;
    position: absolute;
    right: 47px;
    bottom: 0px;
    background: url("../../../assets/images/pc/figure.webp") no-repeat;
    background-size: 100% 100%;
  }
  .logoText {
    height: 530px;
    width: 797px;
    position: absolute;
    left: 169px;
    top: 218px;
    background: url("../../../assets/images/pc/logoText.webp") no-repeat;
    background-size: 100% 100%;
  }
  .downloadBox {
    position: absolute;
    left: 169px;
    bottom: 115px;
    height: 175px;
    width: 871px;
    background: url("./../../../assets/images/pc/installSuggestion.webp")
      no-repeat;
    background-size: 100% 100%;
    .qrcodeBox {
      height: 160px;
      width: 160px;
      background-color: white;
      position: absolute;
      left: 10px;
      top: 10px;
    }
  }
}
</style>
